export default [
    {
        path: '/admin',
        meta: { authRequired: true }, // Set authRequired on the parent
        children: [
            {
                path: '', // This matches /admin
                name: 'default',
                component: () => import('../views/admin/dashboard/index-dashboard.vue'),
                meta: { menu: 'Dashboard' }, // No need to add authRequired here
            },
            {
                path: 'dashboard', // This matches /admin
                name: 'Dashboard',
                component: () => import('../views/admin/dashboard/index-dashboard.vue'),
                meta: { menu: 'Dashboard' }, // No need to add authRequired here
            },
            {
                path: 'platforms',
                name: 'List Platforms',
                component: () => import('../views/admin/platforms/listPlatforms.vue'),
                meta: { menu: 'Settings' },
            },
            {
                path: 'members',
                name: 'Members Manager',
                component: () => import('../views/admin/members/listMembers.vue'),
                meta: { menu: 'Settings' },
            },
            {
                path: 'managers',
                name: 'Account Managers',
                component: () => import('../views/admin/managers/listManagers.vue'),
                meta: { requiredRole: 'admin' }, // Additional meta for this route
            },
            {
                path: 'accounts',
                name: 'Account Levels',
                component: () => import('../views/admin/accounts/listAccounts.vue'),
                meta: { requiredRole: 'admin' }, // Additional meta for this route
            },
            {
                path: 'logger',
                name: 'Logger Samples',
                component: () => import('../views/admin/logger/listLogs.vue'),
                meta: { menu: 'Statistics' }, // Additional meta for this route
            },
            {
                path: 'search/feedstats',
                name: 'Feeds Statistics',
                component: () => import('../views/admin/search/feedStats.vue'),
                meta: { menu: 'Statistics' }, // Additional meta for this route
            },
            {
                path: 'search/linkstats',
                name: 'Links Statistics',
                component: () => import('../views/admin/search/feedStats.vue'),
                meta: { menu: 'Statistics' }, // Additional meta for this route
            }             
        ],
    },
];
